<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_logotypes_and_favicon')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="pb-50 mt-2">
                <h5>{{$t('label_logotypes_and_favicon')}}</h5>
            </b-card-header>

            <b-card-body>
                <b-alert class="m-2" variant="info" show>
                    <div class="">{{$t('message_settings_images')}}</div>
                </b-alert>
                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >
                    <div class="d-flex justify-content-start">
                        <div  class="added-img-wrap">

                            <img v-if="logo.length > 0" alt="logo" :src="logo[0].base64" >
                            <img v-else alt="logo" :src="uploadedLogo? $domain+ 'uploads/logos/' +uploadedLogo : require('@/assets/images/no-image-2.png')" >
                            <!--</a>-->
                            <div v-if="(logo.length > 0 || uploadedLogo)"  @click="logo.length > 0? logo = [] :deleteLogo()" class="delete-img server-deleting" >
                                <feather-icon class="cursor-pointer"  icon="TrashIcon"></feather-icon>
                            </div>
                        </div>
                    </div>

                    <validation-provider
                            class="mt-2"
                            :name="$t('label_logo')"
                            slim
                            #default="validationProps"

                    >

                        <b-form-group
                                :label="$t('label_logo')"
                        >
                            <file-input

                                    v-model="logo"
                                    class="file--uploader"
                                    :max-files-count='10'
                                    :required="true"
                                    :multiple="false"
                                    allowed-extensions="*"
                                    :max-file-size='15'
                                    :url="''"
                            >
                                <template #uploadBody>
                                    <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                    <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                    {{$t('label_add_file')}}
                                </template>

                                <template #errorBug="fileErrors">

                                    <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </template>
                            </file-input>
                        </b-form-group>
                        <span class="mb-3 d-flex align-items-center "><feather-icon class="text-primary mr-1" icon="InfoIcon"></feather-icon> {{$t('label_max_file_size')}}: 760x230(100kb)</span>
                    </validation-provider>

                    <div class="d-flex justify-content-start">
                        <div  class="added-img-wrap">

                            <img v-if="logo_mini.length > 0" alt="logo" :src="logo_mini[0].base64" >
                            <img v-else alt="logo mini" :src="uploadedLogoMini? $domain+ 'uploads/logos/' + uploadedLogoMini : require('@/assets/images/no-image-2.png')" >
                            <!--</a>-->
                            <div v-if="(logo_mini.length > 0 || uploadedLogoMini)"  @click="logo_mini.length > 0? logo_mini = [] :deleteLogoMini()" class="delete-img server-deleting" >
                                <feather-icon class="cursor-pointer"  icon="TrashIcon"></feather-icon>
                            </div>
                        </div>
                    </div>

                    <validation-provider
                            class="mt-2"
                            :name="$t('label_logo') + ' mini'"
                            slim
                            #default="validationProps"
                    >

                        <b-form-group
                                :label="$t('label_logo') + ' mini'"
                        >
                            <file-input

                                    v-model="logo_mini"
                                    class="file--uploader"
                                    :max-files-count='10'
                                    :required="true"
                                    :multiple="false"
                                    allowed-extensions="*"
                                    :max-file-size='15'
                                    :url="''"
                            >
                                <template #uploadBody>
                                    <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                    <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                    {{$t('label_add_file')}}
                                </template>

                                <template #errorBug="fileErrors">

                                    <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                    <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </template>
                            </file-input>
                        </b-form-group>
                        <span class="mb-3 d-flex align-items-center "><feather-icon class="text-primary mr-1" icon="InfoIcon"></feather-icon> {{$t('label_max_file_size')}}: 250x250(100kb)</span>
                    </validation-provider>

                    <div class="d-flex justify-content-start">
                        <div  class="added-img-wrap">

                            <img v-if="favicon.length > 0" alt="favicon" :src="favicon[0].base64" >
                            <img v-else alt="logo" :src="uploadedFavicon? $domain+'uploads/favicons/' +uploadedFavicon : require('@/assets/images/no-image-2.png')" >
                            <!--</a>-->
                            <div v-if="(favicon.length > 0 || uploadedFavicon)"  @click="favicon.length > 0? favicon = [] :deleteFavicon()" class="delete-img server-deleting" >
                                <feather-icon class="cursor-pointer"  icon="TrashIcon"></feather-icon>
                            </div>
                        </div>
                    </div>

                    <validation-provider
                            class="mt-2"
                            :name="$t('label_favicon')"
                            slim
                            #default="validationProps"

                    >
                        <b-form-group
                                :label="$t('label_favicon')"
                        >
                        <file-input

                                v-model="favicon"
                                class="file--uploader"
                                :max-files-count='10'
                                :required="true"
                                :multiple="false"
                                allowed-extensions="*"
                                :max-file-size='15'
                                :url="''"
                        >
                            <template #uploadBody>
                                <feather-icon class="mr-1" data-toggle icon="DownloadCloudIcon" size="24"/>
                                <!--<img :src="require('@/assets/images/upload.svg')" class="mr-1">-->
                                {{$t('label_add_file')}}
                            </template>

                            <template #errorBug="fileErrors">

                                <span v-if="validationProps.failedRules.length > 0 || fileErrors.errors.length > 0">{{validationProps.failedRules.length  > 0 ?  validationProps.errors[0] : fileErrors.errors[0] }}</span>
                                <b-form-invalid-feedback :state="validationProps.failedRules.length > 0">
                                    {{ validationProps.errors[0] }}
                                </b-form-invalid-feedback>
                            </template>
                        </file-input>
                        </b-form-group>
                        <span class="mb-1 d-flex align-items-center"><feather-icon class="text-primary mr-1" icon="InfoIcon"></feather-icon> {{$t('label_max_file_size')}}: 310x310(100kb)</span>

                    </validation-provider>




                    <div class="d-flex  justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ $t('label_save') }}
                        </b-button>
                        <b-button @click.prevent="$router.go(-1)"
                                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                  type="button"
                                  variant="outline-secondary"

                        >{{ $t('label_cancel') }}
                        </b-button>
                    </div>
                </validation-observer>
            </b-card-body>
        </b-card>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
        BFormGroup,
        BFormInput, BFormInvalidFeedback, BFormTextarea

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import  fileInput from '@/views/components/fileUploadInput'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver, BFormTextarea,
            fileInput
        },
        props: ['selectOption'],

        data() {
            return {
                PREFIX: 'system_images',

                logo: [],
                uploadedLogo: false,
                logo_mini: [],
                uploadedLogoMini: false,
                favicon: [],
                uploadedFavicon: false,
            }

        },

        created() {
            this.getPageData();
        },

        methods: {

            getPageData(){
                this.async('get', '/general_settings/' + this.PREFIX, {params:{}}, function (resp) {
                    this.uploadedLogo = resp.data.logo;
                    this.uploadedLogoMini = resp.data.logo_mini;
                    this.uploadedFavicon = resp.data.favicon;
                    localStorage.setItem('app_logo', resp.data.logo);
                    localStorage.setItem('app_logo_mini', resp.data.logo_mini);
                    this.$store.state.app.app_logo = resp.data.logo;
                    this.$store.state.app.app_logo_mini = resp.data.logo_mini;
                });
            },

            onSubmit() {

                let formData = new FormData();

                    formData.append('logo', this.logo[0]);

                    formData.append('favicon', this.favicon[0]);

                    formData.append('logo_mini', this.logo_mini[0]);

                this.async('post', 'general_settings/'+this.PREFIX , formData, function (resp) {
                        this.logo = [];
                        this.logo_mini = [];
                        this.favicon = [];
                        this.getPageData();
                }, false, {headers:{'Content-Type': 'multipart/form-data'}});

            },

            deleteLogo(){
                this.confirmDeleting((result) => {
                    this.async('delete', '/general_settings/logo/1' , {}, function (resp) {
                        this.uploadedLogo = false;
                    });
                });
            },
            deleteLogoMini(){
                this.confirmDeleting((result) => {
                    this.async('delete', '/general_settings/logo_mini/1' , {}, function (resp) {
                        this.uploadedLogoMini = false;
                    });
                });
            },
            deleteFavicon(){
                this.confirmDeleting((result) => {
                    this.async('delete', '/general_settings/favicon/1' , {}, function (resp) {
                        this.uploadedFavicon = false;
                    });
                });
            }
        }


    }
</script>

<style scoped>
    .added-img-wrap{
        border: 1px solid #BDBDBD;
        border-radius: 15px;
        align-self: center;
        display: flex;
        align-items: center;
        padding: 10px;
        position: relative;
    }
    .added-img-wrap img{
        object-fit: contain;
        width: 100%;
        max-width: 130px;
        min-width: 130px;
        height: 100%;
        max-height: 224px;
        border-radius: 5px;
    }
    .delete-img{
        position: absolute;
        border-radius: 50%;
        border-style: none;
        top: 7px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 3px;
        font-size: 15px;
        right: 7px;
        width: 20px;
        height: 20px;
        background-color: #EB5757;
        color: white;
    }
</style>